<template>
  <div class="fullscreen">
    <iframe
      v-if="forceRefresh"
      class="fullscreen"
      :class="{loading}"
      :src="url"
      ref="iframe"
    />
    <div
      v-if="loading"
      class="fullscreen d-flex"
    >
      <div class="loader" />
    </div>
  </div>
</template>

<script>
import themeController from '@/themeController'

export default {
  activated () {
    this.forceRefresh = false
    this.$nextTick(() => {
      this.forceRefresh = true
      this.prepareV5Insertion()
      this.observeMutation()
    })
  },
  created () {
    this.prepareV5Insertion()
  },
  data () {
    return {
      forceRefresh: true,
      loading: false
    }
  },
  computed: {
    observer () {
      return new MutationObserver(this.validateSession)
    }
  },
  methods: {
    async prepareV5Insertion () {
      if (this.loading) { return }
      this.loading = true
      this.$store.commit('setModule', { name: '' })
      sessionStorage.setItem('authentication', JSON.stringify({ token: sessionStorage.getItem('access_token'), canDisconnect: false }))

      // Wait for the iframe to load the DOM
      await this.$waitFor(() => document?.getElementsByTagName?.('iframe')?.[0]?.contentDocument?.head?.childElementCount)

      // Check if the iframe's contentDocument is still here (it would be gone if someone navigate during loading)
      const contentDocument = await this.$waitFor(() => {
        const contentDocument = document?.getElementsByTagName?.('iframe')?.[0]?.contentDocument
        return (!contentDocument || contentDocument.URL === 'about:blank') ? false : contentDocument
      })

      if (!contentDocument) {
        return
      }
      const css = `
            .appNameSection,
            #recent,
            #mainMenu i,
            .currentUser,
            [src="'application/partials/message/headerMessages.html'"],
            [src="'application/partials/notification/headerNotification.html'"],
            .cotGridBulkActionBtn,
            [ng-click="showAccountPage()"] {
              display: none !important;
              pointer-events: none
            }
            #mainMenu, .highcharts-title {
              pointer-events: none
            }
            #loading-bar-spinner {
              left: 8px !important
            }
            [ng-controller="menuController"] {
              margin-left: 38px
            }
            #header, #header button, .tile, div.ngRow.selected, div.ngRow:hover, div.ngRow:hover a, div.selectable.selected,
            .gridview-header button[ng-click]:hover, button[ng-click].inverted:hover, button[ng-click].inverted:hover i,
            .tableSequence table tbody[ng-model] tr td, #divisions .divisionTree div.isSelected, #divisions .divisionTree div.isNotSelected:hover,
            #securityManagement .selectableItems div.selected {
              background: ${themeController.primary} !important;
              color: hsl(0, 0%, ${themeController.getLightness(themeController.primary)}%) !important;
            }
            .cotGridBulkActionSelector {
              color: hsl(0, 0%, ${themeController.getLightness(themeController.primary)}%) !important;
            }
            button[ng-click] {
              background: ${themeController.primary};
              color: hsl(0, 0%, ${themeController.getLightness(themeController.primary)}%);
            }
            div.toolBar button.bgSelectedBackgroundColor {
              border-bottom-color: ${themeController.primary} !important;
            }
            #footer {
              background: transparent !important
            }
            [cot-item-click]:not([cot-item-click*="navigateDetail"]):not([cot-item-click*="navigateToDetail"]):not([cot-item-click="setCurrentSelectedUser($item)"]):not([cot-item-click="setCurrentSelectedContributeur($item)"]):not([cot-item-click="addUser($item)"]):not([cot-item-click="addAccount($item)"]):not([cot-item-click="removeAccount($item)"]):not([cot-item-click="assignGroup($item)"]) .ngCell {
              pointer-events: none !important
            }
            .ngCellText[ng-click*="navigate"] {
              pointer-events: none !important;
            }
            .ngCellText[ng-click*="Attachment"],.ngCellText[ng-click*="Note"] {
              pointer-events: all !important;
            }
            #mainSections {
              pointer-events: none !important;
            }
            ${this.additionalCSS}
          `
      this.loading = false
      const el = document.createElement('style')
      el.innerHTML = css
      contentDocument.head.appendChild(el)
    },
    async observeMutation () {
      this.observer.disconnect()
      const iframe = await this.$waitFor(() => this.$refs.iframe)
      // Fix cypress crash if iframe is not loaded fast enough
      if (!iframe) { return }
      this.observer.observe(iframe, { attributes: true, childList: false })
      this.validateSession()
    },
    validateSession () {
      this.$http().get('/core/v6/users/authenticating-user').catch(() => { /* Fail silently */ })
    }
  },
  mounted () {
    this.prepareV5Insertion()
    this.observeMutation()
  },
  props: {
    additionalCSS: String,
    url: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.fullscreen
  height 100%
  width 100%

iframe
  border none

.loading
  display none

.loader, .loader:after
  border-radius 50%
  width 10em
  height 10em

@css {
  .loader {
    margin:  auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid var(--primary-color);
    border-right: 1.1em solid var(--primary-color);
    border-bottom: 1.1em solid var(--primary-color);
    border-left: 1.1em solid transparent;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 0.75s infinite linear;
    animation: load 0.75s infinite linear;
}
}

@keyframes load
  0%
    -webkit-transform rotate(0deg)
    transform rotate(0deg)

  100%
    -webkit-transform rotate(360deg)
    transform rotate(360deg)

@keyframes load
  0%
    -webkit-transform rotate(0deg)
    transform rotate(0deg)

  100%
    -webkit-transform rotate(360deg)
    transform rotate(360deg)
</style>
